html {
  height: 100%;
}
body {
  background: #00c8ff;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.header {
  width: 100%;
  max-width: 820px;
  margin: 50px auto 0 auto;
  display: flex;
  justify-content: space-between;
}
.header__logo img {
  display: block;
  margin: 0 auto;
  width: 220px;
  height: 49px;
}
.header__store {
  width: 290px;
  justify-content: space-between;
  display: flex;
}
.header__store a {
  transition: opacity 0.3s ease;
}
.header__store a:hover {
  opacity: 0.6;
}
.header__store img {
  height: 40px;
  margin: auto;
}
.content {
  height: 376px;
  width: 820px;
  margin: auto;
  display: flex;
}
.content__video {
  margin-right: 50px;
  position: relative;
}
.content__video-player {
  border-radius: 17px;
  width: 188px;
  height: 376px;
  overflow: hidden;
  -webkit-box-shadow: 0px 4px 14px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 0px 4px 14px 0px rgba(0,0,0,0.15);
  box-shadow: 0px 4px 14px 0px rgba(0,0,0,0.15);
  transform: translateX(0px);
}
.content__video-placeholder {
  position: absolute;
  width: 188px;
  border-radius: 17px;
  display: none;
}
.content__video height video_height {
  position: absolute;
  display: none;
}
.content__video-prev,
.content__video-next {
  display: none;
}
.content__video-carousel {
  width: 940px;
  height: 376px;
  overflow: hidden;
  display: flex;
  transform: translateX(0px);
}
.content__video-carousel video {
  transform: translate3d(0, 0, 0);
  width: 188px;
  height: 376px;
  object-fit: cover;
}
.content__mtext {
  display: none;
}
.content__text {
  width: 500px;
}
.content__text-row {
  cursor: pointer;
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  margin: 30px 0;
  line-height: 42px;
  opacity: 0.4;
  transition: opacity 0.3s ease;
}
.content__text-row:first-child {
  margin-top: 15px;
}
.content__text-row.active {
  opacity: 1;
}
.content__mstore {
  display: none;
}
.content__mstore a {
  transition: opacity 0.3s ease;
}
.content__mstore a:hover {
  opacity: 0.6;
}
.footer {
  height: 23px;
  width: 100%;
  max-width: 820px;
  margin: 0 auto 50px auto;
  display: flex;
  justify-content: space-between;
  font-size: 14x;
  font-weight: 500;
}
.footer__item {
  display: flex;
}
.footer__item:hover {
  opacity: 0.6;
}
.footer .vk_checkyou {
  color: #fff;
  display: flex;
}
.footer .vk_checkyou img {
  width: 20px;
  height: 20px;
  margin: 1px 8px 0 0;
}
.footer__right {
  display: flex;
}
.footer__right a {
  color: #fffe00;
  text-decoration: none;
}
.footer__right a:not(:last-child):not(:first-child) {
  margin: 0 30px;
}
.circle {
  display: block;
  width: 150px;
  height: 150px;
  position: fixed;
  left: 20%;
  top: 20%;
  z-index: -1;
}
.circle.a {
  top: 5%;
  left: 73%;
}
.circle.b {
  width: 120px;
  height: 120px;
  top: 20%;
  left: 20%;
}
.circle.c {
  width: 200px;
  height: 200px;
  top: 45%;
  left: 28%;
}
.circle.d {
  top: 70%;
  left: 75%;
}
.circle.e {
  top: 25%;
  left: 65%;
}
@media screen and (max-width: 800px) {
  body {
    overflow-y: scroll;
    overflow-x: hidden;
    height: auto;
  }
  .header {
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .header__logo {
    width: 100%;
  }
  .header__logo img {
    display: block;
    margin: 0 auto;
    width: 220px;
    height: 49px;
  }
  .header__store {
    display: none;
  }
  .content {
    width: 100%;
    flex-direction: column;
    height: auto;
  }
  .content__video {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    height: 298px;
    flex-shrink: 0;
  }
  .content__video-player {
    position: relative;
    width: 149px;
    height: 298px;
    border-radius: 12px;
  }
  .content__video-placeholder {
    width: 149px;
    height: 298px;
  }
  .content__video-carousel {
    width: 745px;
    height: 298px;
  }
  .content__video-carousel video {
    width: 149px;
    height: 298px;
  }
  .content__video-prev {
    display: block;
    margin: auto 30px auto 0;
  }
  .content__video-prev img {
    transform: rotateZ(180deg);
  }
  .content__video-next {
    display: block;
    margin: auto 0 auto 30px;
  }
  .content__text {
    display: none;
  }
  .content__mtext {
    width: 320px;
    display: block;
    overflow: hidden;
    margin: 0 auto 0 auto;
    flex-shrink: 0;
    position: relative;
    box-sizing: border-box;
  }
  .content__mtext:before {
    content: '';
    display: block;
    background: #f00;
    width: 10px;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 10;
    background: linear-gradient(90deg, #00c8ff, rgba(0,200,255,0));
  }
  .content__mtext:after {
    content: '';
    display: block;
    background: #f00;
    width: 10px;
    height: 100%;
    top: 0;
    right: 0;
    position: absolute;
    z-index: 10;
    background: linear-gradient(270deg, #00c8ff, rgba(0,200,255,0));
  }
  .content__mtext .content__text-row {
    box-sizing: border-box;
    padding: 0 10px;
  }
  .content__mtext-carousel {
    display: flex;
    width: 1600px;
    color: #fff;
  }
  .content__mtext-carousel .content__text-row {
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .content__mtext-carousel h2 {
    width: 20%;
    flex-shrink: 0;
    text-align: center;
    font-size: 24px;
    line-height: 30px;
  }
  .content__mstore {
    display: block;
    margin-bottom: 30px;
  }
  .content__mstore .appstore {
    width: 295px;
    background: #000;
    border-radius: 20px;
    margin: 0 auto;
    display: none;
  }
  .content__mstore .gplay {
    width: 295px;
    background: #000;
    border-radius: 20px;
    margin: 0 auto;
    display: block;
  }
  .content__mstore img {
    display: block;
    margin: 0 auto;
    height: 60px;
  }
  @supports (-webkit-overflow-scrolling: touch) {
    .content__mstore .appstore {
      display: block;
    }
    .content__mstore .gplay {
      display: none;
    }
  }
  .footer {
    width: 100%;
    height: auto;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .footer .vk_checkyou {
    justify-content: center;
    margin-bottom: 10px;
  }
  .footer__right {
    flex-direction: column;
  }
  .footer__right a {
    justify-content: center;
  }
  .footer__right a:not(:last-child):not(:first-child) {
    margin: 17px 0;
  }
  .circle.a {
    top: 5%;
    left: 80%;
  }
  .circle.b {
    width: 80px;
    height: 80px;
    top: 20%;
    left: 20%;
  }
  .circle.c {
    width: 120px;
    height: 120px;
    top: 45%;
    left: 55%;
  }
  .circle.d {
    top: 60%;
    left: 15%;
  }
  .circle.e {
    top: 25%;
    left: 65%;
  }
}
@media screen and (max-width: 350px) {
  .content__video {
    height: 258px;
  }
  .content__video-player {
    width: 129px;
    height: 258px;
  }
  .content__video-placeholder {
    width: 129px;
    height: 258px;
  }
  .content__video-carousel {
    width: 645px;
    height: 258px;
  }
  .content__video-carousel video {
    width: 129px;
    height: 258px;
  }
}
